// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import { Notifier } from '@airbrake/browser';

window.addEventListener('load', function() {
  if (!window.ENV.AIRBRAKE_PROJECT_ID) return

  const airbrake = new Notifier({
    projectId: window.ENV.AIRBRAKE_PROJECT_ID,
    projectKey: window.ENV.AIRBRAKE_PROJECT_KEY,
    environment: window.ENV.RAILS_ENV
  })
})
